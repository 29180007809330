<template>
  <div class="billing_red_invoice_ossue_view">
    <div :class="isRetail ? 'content-tab' : ''">
      <el-tabs v-model="activeName" @tab-click="handleInvoiceOpenTabsChange">
        <el-tab-pane label="红票开具" name="billing" />
        <el-tab-pane label="我发出的确认单" name="meSend" />
        <el-tab-pane label="我收到的确认单" name="sendMe" />
      </el-tabs>
      <billing-red-invoice-list :equipment="equipment" v-if="activeName == 'billing'"></billing-red-invoice-list>
      <red-invoice-me-send-list v-if="isRetail && activeName == 'meSend'" :billingTradeIds="billingTradeIds"></red-invoice-me-send-list>
      <red-invoice-send-me-list :equipment="equipment" v-if="isRetail && activeName == 'sendMe'" :billingTradeIds="billingTradeIds"></red-invoice-send-me-list>
    </div>
  </div>
</template>

<script>
import BillingRedInvoiceList from './BillingRedInvoiceList';
import RedInvoiceSendMeList from './RedInvoiceSendMeList';
import RedInvoiceMeSendList from './RedInvoiceMeSendList';
import {getCurrentUserDefaultConfig, getEquipmentByOrgId} from "@/service/invoice-open";

export default {
  name: 'SalHpkj',
  components: {
    BillingRedInvoiceList,
    RedInvoiceSendMeList,
    RedInvoiceMeSendList
  },
  data() {
    return {
      activeName: '',
      selectForm: {
        orgId: localStorage.getItem('orgId') ? Number(localStorage.getItem('orgId')) : '',
        name: ''
      },
      billingTradeIds: '',
      equipment: {}
    };
  },
  computed: {
    isRetail() {
      return true;
    }
  },
  async created() {
    this.activeName = 'billing';
    const billingTradeIdsQuery = this.$route.query.billingTradeIds;
    if (billingTradeIdsQuery) {
      this.billingTradeIds = window.atob(billingTradeIdsQuery);
    }
    await this.handleEquipmentChange(localStorage.getItem('orgId'))
  },
  methods: {
    async handleEquipmentChange(orgId) {
     await getEquipmentByOrgId({ orgId: orgId }).then((res) => {
        const { success, data } = res;
        if (success) {
          this.equipment = data;
        }
      });
    },
    // 发票开具Tabs切换
    handleInvoiceOpenTabsChange(tab) {
      this.activeName = tab.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.billing_red_invoice_ossue_view {
  position: relative;
}
</style>
